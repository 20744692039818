<template>
  <div class="deallist">
    <el-button @click="drawer = true" type="primary" style="margin-left: 16px;">
      点我打开
    </el-button>

    <!-- 抽屉 -->
    <el-drawer title="我是第一层" :visible.sync="drawer" size="50%">
      <div>
        <el-button @click="innerDrawer = true">打开里面的!</el-button>
        <el-drawer
          title="我是第二层"
          :append-to-body="true"
          :visible.sync="innerDrawer"
          size="40%"
        >
          <div>
            <el-button @click="innerDrawers = true">打开里面的!</el-button>
            <el-drawer
              size="30%"
              title="我是第三层"
              :append-to-body="true"
              :before-close="handleClose"
              :visible.sync="innerDrawers"
            >
              <p>_(:зゝ∠)_</p>
            </el-drawer>
          </div>
        </el-drawer>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: "dealList",
  data() {
    return {
      drawer: false,
      innerDrawer: false,
      innerDrawers: false,
    };
  },
  methods: {
    handleClose(done) {
      this.$confirm("还有未保存的工作哦确定关闭吗？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.deallist {
	width: 100%;
	height: 100%;
	background: #fff;
}
</style>
